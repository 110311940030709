/**Ajouter un champs avec une seule valeur */
export function addFieldWithSeveralValues(fields, label, values, divider) {
  let field = {
    label,
    values,
    divider,
  };

  fields.push(field);
}

/**Ajouter un champs avec plusieurs valeurs */
export function addFieldWithOneValue(fields, label, value, divider) {
  addFieldWithSeveralValues(fields, label, [value], divider);
}
