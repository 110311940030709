<template>
  <div>
    <slot name="header"></slot>

    <v-card flat outlined class="mx-auto">
      <slot name="title">
        <v-card-title class="font-weight-regular">{{ title }} </v-card-title>
      </slot>

      <v-card-text v-for="(field, i) in fields" :key="i">
        <v-row no-gutters class="mb-5 mt-1" align="center">
          <v-col :cols="clabel" justify="center" align-self="start">
            <div class="capitalize font-weight-regular">
              {{ field.label }}
            </div>
          </v-col>
          <v-col :cols="cvalue" align-self="start">
            <!-- <div>{{ field.value }}</div> -->
            <div v-for="(value, j) in field.values" :key="j">{{ value }}</div>
          </v-col>
        </v-row>
        <v-divider v-if="field.divider"></v-divider>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DetailViewComponent",

  mixins: [],
  props: {
    /**le titre de la vue 'détail'. */
    title: {
      type: String,
    },

    /** les données à afficher (label + value).*/
    fields: {
      type: Array,

      default: function () {
        return [];
      },
    },
    /**la largeur de la colonne 'LABEL' */
    clabel: {
      type: Number,
      default: 5,
    },
    /**la largeur de la colonne 'VALEUR' */
    cvalue: {
      type: Number,
      default: 7,
    },
  },
  data() {    
    return {};
  },
  methods: {
    onBack() {
      this.$emit("back");
    },
  },
  
  computed: {},
  mounted() {},
};
</script>

<style>
</style>