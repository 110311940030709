var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("header"),
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { flat: "", outlined: "" } },
        [
          _vm._t("title", function () {
            return [
              _c("v-card-title", { staticClass: "font-weight-regular" }, [
                _vm._v(_vm._s(_vm.title) + " "),
              ]),
            ]
          }),
          _vm._l(_vm.fields, function (field, i) {
            return _c(
              "v-card-text",
              { key: i },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "mb-5 mt-1",
                    attrs: { "no-gutters": "", align: "center" },
                  },
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: _vm.clabel,
                          justify: "center",
                          "align-self": "start",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "capitalize font-weight-regular" },
                          [_vm._v(" " + _vm._s(field.label) + " ")]
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: _vm.cvalue, "align-self": "start" } },
                      _vm._l(field.values, function (value, j) {
                        return _c("div", { key: j }, [_vm._v(_vm._s(value))])
                      }),
                      0
                    ),
                  ],
                  1
                ),
                field.divider ? _c("v-divider") : _vm._e(),
              ],
              1
            )
          }),
          _c("v-divider"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }